/* eslint-disable no-bitwise */
<template>
  <div class="infomation-and-adress">
    <b-media class="mb-1">
      <template #aside>
        <div id="appImgBusiness">
          <div
            v-if="valueData.avatar&&valueData.avatar!==null&&valueData.avatar!==''"
            class="image-input image-input-active d-flex"
          >
            <div
              class="image-preview"
            >
              <img
                class="img-responsive h-100"
                :src="($serverfile+valueData.avatar)"
              >
              <b-dropdown
                class="remove-file"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="CameraIcon"
                    size="16"
                    class="icon-camera-hover"
                  />
                </template>
                <b-dropdown-item
                  @click="openinput"
                >
                  <feather-icon
                    icon="UploadIcon"
                    class="mr-50"
                  />
                  <span>Tải ảnh lên</span>
                </b-dropdown-item>
                <input
                  id="vue-file-upload-input"
                  type="file"
                  class="d-none"
                  @change="addImage"
                >
                <b-dropdown-item
                  @click="removeFile(index)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  <span>Xóa ảnh</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div
            v-else
            class="image-input image-input-tbd d-flex"
          >
            <div
              class="image-preview dropzone d-flex justify-content-center align-items-center"
              @click="openinput"
            >
              <feather-icon
                icon="CameraIcon"
                class="icon-camera"
              />
            </div>
            <input
              id="vue-file-upload-input"
              type="file"
              class="d-none"
              @change="addImage"
            >
          </div>
        </div>
      </template>
    </b-media>
    <information-and-adress-title
      :icon="'InfoIcon'"
      :title="'Thông tin'"
    />
    <div class="infomation-and-adress__input__01">
      <b-row>
        <!--===============  input mã doanh nghiệp =================-->
        <b-col
          v-if="province !== 'Đắk Lắk'"
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="basicInput"
          >
            <label
              for="code"
            >Mã doanh nghiệp<span
              class="required"
            >(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="{required: `Mã doanh nghiệp là bắt buộc`,}"
            >
              <b-form-input
                id="code"
                v-model="valueData.code"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          v-else
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="basicInput"
          >
            <label
              for="code"
            >Mã doanh nghiệp<span
              class="required"
            >(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="{required: `Mã doanh nghiệp là bắt buộc`,}"
            >
              <b-form-input
                id="code"
                v-model="valueData.taxNumber"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--===============  input tên doanh nghiệp =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="name"
          >
            <label
              for="name"
            >Tên doanh nghiệp<span
              class="required"
            >(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="{required: `Tên doanh nghiệp là bắt buộc`,}"
            >
              <b-form-input
                id="name"
                v-model="valueData.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--===============  input tên doanh nghiệp tiếng anh=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="englishName"
          >
            <label
              for="englishName"
            >Tên DN tiếng anh</label>
            <b-form-input
              id="englishName"
              v-model="valueData.englishName"
            />
          </b-form-group>
        </b-col>
        <!--===============  input tên doanh nghiệp viết tắt=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="abbreviationName"
          >
            <label
              for="abbreviationName"
            >Tên DN viết tắt</label>
            <b-form-input
              id="abbreviationName"
              v-model="valueData.abbreviationName"
            />
          </b-form-group>
        </b-col>
        <!--===============  input số quyết định thành lập=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="name12"
          >
            <label
              for="name12"
            >Số quyết định thành lập<span
              class="required"
            >(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="{required: `Số quyết định thành lập là bắt buộc`,}"
            >
              <b-form-input
                id="name12"
                v-model="valueData.decisionEstablish"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!--===============  input cơ quan quản lý cấp trên=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="abbreviationName"
          >
            <label
              for="abbreviationName"
            >Cơ quan quản lý cấp trên</label>
            <b-form-input
              id="abbreviationName"
              v-model="valueData.managementAgencyName"
            />
          </b-form-group>
        </b-col>
        <!--===============  input Đơn vị cấp=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="unitIssued"
          >
            <label
              for="unitIssued"
            >Đơn vị cấp<span
              class="required"
            >(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="{required: `Đơn vị cấp là bắt buộc`,}"
            >
              <b-form-input
                id="unitIssued"
                v-model="valueData.unitIssued"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--===============  input Ngày cấp=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="dateIssuedString"
          >
            <label
              for="dateIssuedString"
            >Ngày cấp<span
              class="required"
            >(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="{required: `Ngày cấp là bắt buộc`,}"
            >
              <date-time-picker
                v-model="valueData.dateIssuedString"
                :disabledInput="true"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--===============  input Đơn vị cấp=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="unitIssued"
          >
            <label
              for="unitIssued"
            >Nơi cấp<span
              class="required"
            >(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="{required: `Nơi cấp là bắt buộc`,}"
            >
              <b-form-input
                id="unitIssued"
                v-model="valueData.locationIssued"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--===============  input ngày hết hạng giấy phép kinh doanh=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="dateExpiredString"
          >
            <label
              for="dateExpiredString"
            >Ngày hết hạn giấy phép kinh doanh(Nếu ngày hết hạn vô thời hạn thì để trống)</label>
            <date-time-picker
              v-model="valueData.dateExpiredString"
              :disabledInput="true"
              :showClose="true"
            />
          </b-form-group>
        </b-col>
        <!--===============  input Mã số thuế=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="taxNumber"
          >
            <label
              for="taxNumber"
            >Mã số thuế<span
              class="required"
            >(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="{required: `Mã số thuế là bắt buộc`,}"
            >
              <b-form-input
                id="taxNumber"
                v-model="valueData.taxNumber"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--===============  input Loại hình doanh nghiệp  =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="basicInput"
          >
            <label
              for="label"
            >Loại hình doanh nghiệp<span
              class="required"
            >(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="{required: `Loại hình doanh nghiệp là bắt buộc`,}"
            >
              <v-select
                v-model="valueData.bussinessTypeId"
                :reduce="data => data.id"
                :label="'name'"
                :placeholder="`Lựa chọn loại hình doanh nghiệp `"
                :options="selectbussinessType || []"
                :class="{'is-invalid': !!errors.length}"
                :state="errors.length > 0 ? false:null"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--===============  input Ngành nghề kinh doanh=================-->
        <b-col
          md="4"
          xl="4"
          class="validate-border"
        >
          <b-form-group
            label-for="bussinessSectorId"
          >
            <label for="InputHelp">Ngành nghề kinh doanh chính<span
              class="required"
            >(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="{required: `Ngành nghề kinh doanh chính là bắt buộc`,}"
            >
              <business-sector-select
                v-model="valueData.bussinessSectorId"
                :class="{'is-invalid': !!errors.length}"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--===============  input loại doanh nghiệp=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="bussinessClass"
          >
            <label
              for="bussinessClass"
            >Loại doanh nghiệp<span
              class="required"
            >(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="{required: `Loại doanh nghiệp là bắt buộc`,}"
            >
              <v-select
                v-model="valueData.bussinessClass"
                :reduce="data => data.id"
                :label="'name'"
                :placeholder="`Lựa chọn loại doanh nghiệp `"
                :options="selectbussinessClassify"
                :class="{'is-invalid': !!errors.length}"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        </b-col>

        <!-- Kiểu người dùng-->
        <!-- <b-col
          md="4"
          xl="4"
        >
          <b-form-group label-for="basicInput">
            <label for="InputHelp">Kiểu người dùng</label>
            <v-select
              v-model="valueData.userTypeId"
              disabled
              :reduce="userType => userType.id"
              label="name"
              placeholder="Lựa chọn kiểu người dùng"
              :options="dataCombobox || []"
            >
              <template
                slot="option"
                slot-scope="option"
              >
                {{ option.name }}
              </template>
              <template #selected-option-container="{ option }">
                <div class="vs__selected">
                  {{ option.name }}
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col> -->
      </b-row>

    </div>
    <information-and-adress-title
      :icon="'MapPinIcon'"
      :title="'Địa chỉ'"
    />
    <div class="infomation-and-adress__input__02">
      <b-row>
        <!--===============  input Địa chỉ=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="address"
          >
            <label
              for="address"
            >Địa chỉ</label>
            <b-form-input
              id="address"
              v-model="valueData.address"
            />
          </b-form-group>
        </b-col>
        <!--===============  input Địa chỉ chi nhánh=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="addressBranch"
          >
            <label
              for="addressBranch"
            >Địa chỉ chi nhánh</label>
            <b-form-input
              id="addressBranch"
              v-model="valueData.addressBranch"
            />
          </b-form-group>
        </b-col>
        <!--===============  input Website =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="website"
          >
            <label
              for="website"
            >Website</label>
            <b-form-input
              id="website"
              v-model="valueData.website"
            />
          </b-form-group>
        </b-col>
        <!--===============  input Số điện thoại =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="phoneNumber"
          >
            <label
              for="phoneNumber"
            >Số điện thoại</label>
            <b-form-input
              id="phoneNumber"
              v-model="valueData.phoneNumber"
              type="number"
            />
          </b-form-group>
        </b-col>
        <!--===============  input Email =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="email"
          >
            <label
              for="email"
            >Email</label>
            <b-form-input
              id="email"
              v-model="valueData.email"
            />
          </b-form-group>
        </b-col>
        <!--===============  input Fax =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="Fax"
          >
            <label
              for="Fax"
            >Fax</label>
            <b-form-input
              id="Fax"
              v-model="valueData.fax"
            />
          </b-form-group>
        </b-col>

        <!--===============  select Tỉnh/Thành phố =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="provinceId"
          >
            <label
              for="provinceId"
            >Tỉnh/Thành phố</label>
            <v-select
              v-model="valueData.provinceId"
              :reduce="data => data.id"
              :label="'name'"
              :placeholder="`Lựa chọn tỉnh/Thành phố `"
              :options="provinces"
              @input="seclectProvinces"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!--===============  select Quận/Huyện=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="wardId"
          >
            <label
              for="districtId"
            >Quận/Huyện</label>
            <v-select
              v-model="valueData.districtId"
              :reduce="data => data.id"
              :label="'name'"
              :placeholder="`Lựa chọn quận/Huyện `"
              :options="districts"
              @input="seclectDistricts"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!--===============  select Phường/Xã=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="wardId"
          >
            <label
              for="wardId"
            >Phường/Xã</label>
            <v-select
              v-model="valueData.wardId"
              :reduce="data => data.id"
              :label="'name'"
              :placeholder="`Lựa chọn phường/Xã `"
              :options="wards"
              @input="seclectWards"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import {
  BRow, BCol, BFormInput, BFormGroup, BMedia, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import InformationAndAdressTitle from '../components/InformationAndAdressTitle.vue'
import BusinessSectorSelect from '@/views/management-business/business-sector/pages/components/BusinessSectorSelect.vue'
import storeDefault from '@/home/store'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BFormGroup,
    InformationAndAdressTitle,
    BRow,
    BCol,
    BusinessSectorSelect,
    BFormInput,
    ValidationProvider,
    DateTimePicker,
    vSelect,
    BMedia,
    BDropdown,
    BDropdownItem,
  },
  props: {
    dataInforBusiness: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      province: storeDefault.province,
      selectbussinessClass: [
        {
          id: 'StateEnterprises',
          name: 'Doanh nghiệp nhà nước',
        },
        {
          id: 'NonstateEnterPrises',
          name: 'Doanh nghiệp nước ngoài',
        },
        {
          id: 'ForeignInvestedEnterprises',
          name: 'Doanh nghiệp có vốn đầu tư nước ngoài',
        },
        {
          id: 'Organization',
          name: 'Tổ chức',
        },
        {
          id: 'JobCenter',
          name: 'Trung tâm giới thiệu việc làm',
        },
      ], //  Loại doanh nghiệp
      selectbussinessClassify: [
        {
          id: 'Bussiness',
          name: 'Doanh nghiệp',
        },
        {
          id: 'Accreditation',
          name: 'Tổ chức kiểm định',
        },
        {
          id: 'Training',
          name: 'Tổ chức huấn luyện',
        },
        {
          id: 'Health',
          name: 'Trung tâm y tế',
        },
        {
          id: 'JobCenter',
          name: 'Trung tâm giới thiệu việc làm',
        },
        {
          id: 'StateAgencies',
          name: 'Quận/Huyện, Phường/Xã',
        },
      ], // data lựa chọn phân loại doanh nghiệp
      selectbussinessType: [], // data lựa chọn Loại hình doanh nghiệp
      valueData: {
        code: null, // mã doanh nghiệp    đã gắn  value
        name: null, // tên doanh nghiệp     đã gắn  value
        englishName: null, // tên tiếng anh  đã gắn  value
        abbreviationName: null, // tên viết tắt   đã gắn  value
        decisionEstablish: null, // số quyết định  đã gắn  value
        website: null, // trang web đã gắn  value
        unitIssued: null, // đơn vị cấp    đã gắn  value
        phoneNumber: null, // số dt    đã gắn  value
        fax: null, // fax   đã gắn  value
        locationIssued: null, // mơi cấp    đã gắn  value
        email: null, // email   đã gắn  value
        taxNumber: null, // mã số thuế đã gắn  value---------------
        bussinessTypeId: null, // kiểu loại hình doành nghiệp
        address: null, // địa chỉ   đã gắn  value------------------
        bussinessSectorId: null, // ngành nghề kinh doanh
        addressBranch: null, // địa chỉ chi nhánh   đã gắn  value------------------
        userName: null, // tên            ------------đã gắn  value
        password: null, // mật khẩu   ------------đã gắn  value
        emailConfirmed: true,
        bussinessClassify: 'Bussiness', // dã gắn value
        bussinessClass: null, // dã gắn value
        dateExpiredString: null, // ngày hết hạn
        dateIssuedString: null, // ngày cấp
        provinceId: 0,
        districtId: 0,
        wardId: 0,
        rePassword: null,
        managementAgencyName: null, // Cơ quan quản lý cấp trên
        userTypeId: null, // Kiểu người dùng
        avatar: null,
      },
      provinces: [],
      districts: [],
      wards: [],
      againPassworks: false,
    }
  },
  computed: {
    ...mapGetters('userType', ['dataCombobox']),
  },

  watch: {
    dataInforBusiness(val) {
      if (val) {
        this.valueData = this.dataInforBusiness
      }
    },
  },
  mounted() {
    this.doFetchUserTypeCombobox()
  },
  async created() {
    const dataBusinessType = await this.fecthDataBusinessType()
    this.selectbussinessType = dataBusinessType.data.data
    this.provinces = await this.fecthProvinces()
    this.districts = await this.fecthDistricts(this.valueData.provinceId)
    this.wards = await this.fecthWards(this.valueData.districtId)
  },
  methods: {
    ...mapActions('business', ['fecthDataBusinessType', 'fecthProvinces', 'fecthDistricts', 'fecthWards']),
    ...mapActions('userType', [
      'doFetchUserTypeCombobox',
    ]),
    ...mapActions('users', ['uploadFile']),

    // account
    openinput() {
      document.getElementById('vue-file-upload-input').click()
    },
    uploadPhoto() {
      this.openinput()
    },
    async addImage(e) {
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      const model = {
        files: file,
        isSecure: false,
      }
      const response = await this.uploadFile(model)
      this.valueData.avatar = `${response}`
    },
    removeFile(index) {
      this.valueData.avatar = null
    },

    // ******Lựa chon thành phố********
    async seclectProvinces(e) {
      this.valueData.provinceId = e
      this.districts = await this.fecthDistricts(this.valueData.provinceId)
    },
    //* ******Lựa chọn huyện ********* */
    async seclectDistricts(e) {
      this.valueData.districtId = e
      this.wards = await this.fecthWards(this.valueData.districtId)
    },
    async seclectWards(e) {
      this.valueData.wardId = e
    },
  },
}
</script>

<style lang="scss">
.infomation-and-adress{
  padding: 20px;
}
.validate-border {
  .is-invalid {
    border: 0.5px solid red !important;
    border-radius: 5px;
    .vue-treeselect__control {
      border: unset !important;
    }
  }
}
#appImgBusiness {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  margin-right: 20px;
  .btn {
    padding: 4px !important;
  }
  .image-input {
    padding: 3px;
  }

  .image-preview {
    width: 96px;
    height: 96px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 6px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .icon-camera {
    color: white;
    height: 50px;
    width: 50px;
  }
  .dropzone {
    width: 96px;
    height: 96px;
  }
  .dropzone {
    background: #efefef;
    border-radius: 7%;
  }
  .remove-file {
    position: absolute;
    border-radius: 20px;
    margin-top: 28px;
    margin-left: -66px;
    width: 40px;
    height: 40px;
  }
  .remove-file:hover {
    color: white;
    background-color: rgba(130, 134, 139, 0.65);
  }
  .icon-camera-hover {
    width: 28px;
    height: 28px;
    color: rgba(104, 104, 104, 0);
  }
  .icon-camera-hover:hover {
    color: white;
  }
}
</style>
